import React from "react";
import classNames from "classnames";

const InputTextarea = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  id,
  inverse,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <textarea
      name={name}
      id={id || name}
      placeholder={placeholder}
      className={classNames(
        "min-h-[8rem] w-full border-b-2 bg-transparent py-1 text-white",
        _className,
        {
          "border-midnight": !inverse,
          "border-mint": inverse,
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

InputTextarea.defaultProps = {};

export default InputTextarea;
