import React from "react";

const ErrorPage = () => {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center text-center font-serif">
      <h1 className="text-3xl">500</h1>
      <h2 className="text-xl">Oops.</h2>
      <p>Something went wrong. Please try again or check back later.</p>
    </div>
  );
};

ErrorPage.defaultProps = {};

ErrorPage.propTypes = {};

export default ErrorPage;
