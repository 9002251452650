import React from "react";
import classNames from "classnames";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  options,
  inverse,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <select
      name={name}
      id={name}
      className={classNames(
        "max-w-full relative w-full appearance-none overflow-x-hidden border-b-2 bg-transparent py-1 pr-4 leading-normal text-white",
        _className,
        {
          "border-midnight": !inverse,
          "border-mint": inverse,
          "!border-red": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    >
      <>
        <option key="placeholder" value="" className="text-midnight">
          {placeholder || "Select one"}
        </option>
        {options?.map((option, i) => {
          return (
            <option
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              value={option?.slug || option}
              className="text-midnight"
            >
              {option?.name || option}
            </option>
          );
        })}
      </>
    </select>
  );
};

InputText.defaultProps = {};

export default InputText;
