const colors = ["carnation", "midnight", "mint", "gray-light"];

export const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

const getColor = i => {
  // this makes the array loop back to the start
  const newIndex = i % colors.length;
  return colors[newIndex];
};

export default getColor;
