import React from "react";
import classNames from "classnames";

const InputText = ({
  className: _className,
  formState,
  hasError,
  id,
  inverse,
  name,
  placeholder,
}) => {
  const [form, setForm] = formState;

  const handleChange = e => {
    const file = e.target.files[0];
    setForm({ ...form, [name]: file });
  };

  // Uncomment if you want to show a custom button
  // rather than the default input field
  // const clearFile = () => {
  //   input.current.value = "";
  //   handleChange("");
  // };

  // const handleClick = () => {
  //   if (fileUpload) {
  //     clearFile();
  //   } else {
  //     input.current.click();
  //   }
  // };

  // useEffect(() => {
  //   if (value === "") {
  //     clearFile();
  //   }
  // }, [value]);

  return (
    <input
      // ref={input}
      type="file"
      name={name}
      id={id || name}
      placeholder={placeholder}
      className={classNames(
        "max-w-full w-full border-b-2 bg-transparent py-1 text-white",
        _className,
        {
          "border-midnight": !inverse,
          "border-mint": inverse,
          "!border-red": hasError,
        }
      )}
      onChange={handleChange}
    />
  );
};

InputText.defaultProps = {};

export default InputText;
