import React, { Component } from "react";
import DefaultPageContainer from "../layout/DefaultPageContainer";
import Text from "../atoms/Text";
import Container from "../atoms/Container";
import Button from "../atoms/Button";

const FallbackPage = ({ error }) => {
  return (
    <DefaultPageContainer>
      <div className="flex flex-grow flex-col items-center justify-center text-center">
        <Container className="flex flex-col items-center justify-center">
          <Text variant="h1" className="mb-4">
            Something went wrong.
          </Text>
          <Text variant="lg" className="mb-4">
            There was an error. Please try reloading.
          </Text>
          {process.env.GATSBY_IN_DEV && (
            <pre className="mx-auto mb-4 rounded-md border border-gray p-2 text-xs">
              <code>{error}</code>
            </pre>
          )}
          <Button
            onClick={() => {
              if (typeof window !== "undefined") window.location.reload();
            }}
          >
            Reload the page
          </Button>
        </Container>
      </div>
    </DefaultPageContainer>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error(error);
    this.setState({ error: error.message });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <FallbackPage error={error} />;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {};

export default ErrorBoundary;
