const getAnimation = variant => {
  const animation = {
    draw: {
      hidden: { pathLength: 0, pathSpacing: 2 },
      visible: (time = 0) => {
        const delay = time * 0.1;
        return {
          pathLength: 1,
          pathSpacing: 2,
          transition: {
            delay,
            ease: "easeInOut",
            duration: 2,
          },
        };
      },
    },
  };
  return animation[variant];
};

export default getAnimation;
